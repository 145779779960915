<template>
  <div class="ml-detail-question mb-5">
    <div class="container" v-if="detailQuestion">
      <div class="breadcrumd-custom">
        <nuxt-link to="/">Hỏi bài</nuxt-link>
        <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon"/> <span class="active">Chi tiết</span></div>
      <div class="row">
        <div class="col-lg-8 col-xl-9">
          <div class="form-content">
            <div class="user-info form-data">
              <b-avatar :src="detailQuestion.owner.avatar || NoAvatar" class="mr-3 avatar rounded-circle"></b-avatar>
              <div class="content">
                <div class="user-name text-break" v-if="detailQuestion.owner">{{ detailQuestion.owner.fullName }}</div>
                <div class="time-ago">{{ detailQuestion.created_at | ralativeTime }}</div>
                <div class="class-info text-primary">{{ detailQuestion.class.name }} &#8226; {{ detailQuestion.category.name }}</div>
              </div>
            </div>
            <div class="question-content">
              <vue-mathjax :formula="detailQuestion.content" :safe="false"></vue-mathjax>
            </div>
            <div class="question-image d-flex justify-content-center" v-if="detailQuestion.image && detailQuestion.image.length">
              <el-image fit="contain" class="image-container" :src="detailQuestion.image[0]" :preview-src-list="detailQuestion.image"></el-image>
            </div>
            <div class="question-action d-flex align-items-center">
              <span class="action" :class="{'active': detailQuestion.liked}" @click="detailQuestion.liked ? '' : likeQuestionEvent()">
                <span v-html="require('~/assets/mlearn/icons/question/detail/like.svg?raw')"/>
                <span class="action-number">{{ detailQuestion.like }}</span>
              </span>
              <span v-if="authenticated" class="action" :class="{'active': statusFollowQuestion}" @click="followEvent()">
                <span v-html="require('~/assets/mlearn/icons/question/detail/follow.svg?raw')"/>
                <span class="action-number d-none d-lg-inline-block">{{ statusFollowQuestion ? 'Bỏ theo dõi' : 'Theo dõi' }}</span>
              </span>
              <span v-if="authenticated" class="action" :class="{'active': showCommentBox}" @click="showCommentBox = !showCommentBox">
                <span v-html="require('~/assets/mlearn/icons/question/detail/comment.svg?raw')"/>
                <span class="action-number d-none d-lg-inline-block">Bình luận</span>
              </span>
              <span v-if="authenticated && user.uuid !== detailQuestion.created_by" class="action" :class="{'active': statusFlagClaimQuestionActive}" @click="statusFlagClaimQuestionActive ? '' : typeOf(level='QUESTION',questionId) ">
                <span v-html="require('~/assets/mlearn/icons/question/detail/report.svg?raw')"/>
                <span class="action-number d-none d-lg-inline-block">{{ statusFlagClaimQuestionActive ? 'Đã báo vi phạm' : 'Báo cáo vi phạm' }}</span>
              </span>
              <button v-if="canAnswer" @click="answerQuestion" class="btn-answer" :disabled="!canTyping"><img src="~/mlearn/icons/question/detail/answer.svg" class="mr-1" alt=""> Trả lời</button>
              <button v-if="addAnswer && detailQuestion.answers.length >= 3" @click="handerAddAnswer" class="btn-answer">Yêu cầu thêm câu trả lời</button>
              <span v-if="!detailQuestion.allow_answer && !addAnswer" class="font-weight-bold ml-auto" style="color: #9FA2A5;">Đã đủ số câu trả lời</span>
            </div>
            <template v-if="showCommentBox">
              <div class="form-comment form-data align-items-center">
                <img :src="user && user.avatar ? user.avatar : NoAvatar" class="avatar rounded-circle"/>
                <div class="content">
                  <input v-if="authenticated" :disabled="isCommentQuestion" placeholder="Bình luận của bạn..." v-model="comment_question" @keyup.enter="commentQuestion()" class="w-100">
                  <input v-else placeholder="Đăng nhập để bình luận..." v-b-modal.login class="w-100"/>
                </div>
                <span class="ml-2 ml-lg-3" @click="commentQuestion()" v-html="require('~/assets/mlearn/icons/comment/send-comment.svg?raw')"></span>
              </div>
              <div class="list-comment scrollbar">
                <CommentItem v-for="it in detailQuestion.details" :key="it.id" :item="it"/>
                <div class="loadmore pointer" v-if="displayLoadmoreQ" @click="loadMoreComment()">Xem thêm</div>
              </div>
            </template>
            <div class="question-answer">
              <div class="title">Trả lời ({{ detailQuestion.total_answer }})</div>
              <div v-if="!detailQuestion.answers.length" class="no-answer mb-3">
                <span>Câu hỏi chưa có câu trả lời.</span>
                <br class="d-block d-lg-none">
                <span v-if="authenticated && user.uuid !== detailQuestion.owner.uuid">
                  <nuxt-link class="text-primary" :to="{ path: '/tra-loi/' + questionId}">Hãy viết câu trả lời</nuxt-link>
                  <span> để được cộng điểm</span>
                </span>
                <template v-else-if="!authenticated">
                  <span v-b-modal.login class="text-primary">Hãy viết câu trả lời</span> để được cộng điểm
                </template>
              </div>
              <div class="list-answer">
                <AnsweringItem v-for="(answering, index) in listTyping" :key="'answering_'+index" :item="answering"/>
                <AnswerItem v-for="(answerItem, index) in detailQuestion.answers" :key="index" :item="answerItem" :question="detailQuestion" :uuid-type-rating-thanks="uuidTypeRatingThanks" :type-rating="typeRating" :rating-value="ratingValue" :uuid-define-detail-thank="uuidDefineDetailThank" @report="actionReport" :can-action="true"/>
              </div>
              <AIRelative v-if="authenticated && user.uuid === detailQuestion.owner.uuid" :items="dataRelative" @userVote="userVote"/>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-xl-3 pt-3 pt-lg-0">
          <UserInfo class="mb-3"/>
          <Feed class="mb-3"/>
          <Rank :home="true"/>
        </div>
      </div>
      <ModalReport v-if="typeOfObject" :level="typeOfObject" @statusFlagQuestion="setFlag"/>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import cookieParser from 'cookieparser'
import AIRelative from '~/components/mlearn/question/relative.vue'
import UserInfo from '~/components/mlearn/user/info.vue'
import Feed from '~/components/mlearn/feed/index.vue'
import Rank from '~/components/mlearn/rank/index.vue'
import CommentItem from '~/components/mlearn/question/comment.vue'
import AnswerItem from '~/components/mlearn/answer/item.vue'
import AnsweringItem from '~/components/mlearn/answer/answering'
import ModalReport from '~/components/mlearn/modal/report-question.vue'
import Resource from '~/common/api/resource'
import {searchAi} from '~/common/api/search-ai'
import NoAvatar from '~/assets/mlearn/icons/no-avatar.svg'

const qaResource = new Resource('qa')
const ratingResource = new Resource('rating/define')
const ratingDefineResource = new Resource('rating/define/detail')
const createRatingResource = new Resource('rating')
const qaQuestionDetailResource = new Resource('qa/question-detail')
const claimDefineResource = new Resource('claim/define')
const claimListByUserResource = new Resource('claim/listByUser')
const followResource = new Resource('follow')
const getFollowStatusResource = new Resource('follow/status')
const ratingListByUserResource = new Resource('rating/listByUser')
const getCommentQuestionResource = new Resource('qa/lst-question-detail')
const allowAnswerResource = new Resource('qa/allow-answer')
export default {
  components: {
    UserInfo,
    Feed,
    Rank,
    CommentItem,
    ModalReport,
    AnswerItem,
    AnsweringItem,
    AIRelative
  },
  layout: 'mlearn',
  async asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error
  }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const {data} = await qaResource.get(params.slug, token).catch(() => {
      return error({
        statusCode: 404,
        title: 'Câu hỏi không tồn tại',
        message: 'Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.'
      })
    })
    if (!data) {
      return error({
        statusCode: 404,
        title: 'Câu hỏi không tồn tại',
        message: 'Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.'
      })
    }
    const detailQuestion = data
    detailQuestion.like = 0
    detailQuestion.liked = false
    if (detailQuestion.ratings && detailQuestion.ratings.length) {
      detailQuestion.like = detailQuestion.ratings[0].counterDataMap.Like || 0
    }
    const displayLoadmoreQ = detailQuestion.details.length < detailQuestion.total_details
    detailQuestion.answers.forEach((item, ind) => {
      detailQuestion.answers[ind].statusClaim = false
      detailQuestion.answers[ind].like = 0
      detailQuestion.answers[ind].liked = false
      detailQuestion.answers[ind].thank = 0
      detailQuestion.answers[ind].thanked = false
      if (item.ratings.length) {
        detailQuestion.answers[ind].like = item.ratings[0].counterDataMap.Like || 0
        detailQuestion.answers[ind].thank = item.ratings[0].counterDataMap.Thank || 0
      }
    })
    return {
      questionId: params.slug,
      detailQuestion,
      tmp_detailQuestion: detailQuestion,
      displayLoadmoreQ,
      listTyping: []
    }
  },
  data () {
    return {
      NoAvatar,
      typeRating: {
        question: '100',
        answer: '101',
        comment: '102'
      },
      ratingValue: {
        like: 'Like',
        thank: 'Thank'
      },
      options: {
        navbar: false,
        title: false,
        toolbar: {
          zoomIn: true,
          zoomOut: true,
          oneToOne: true,
          reset: false,
          rotateRight: true,
          rotateLeft: true
        }
      },
      countTimeFollow: 1,
      loading: false,
      curent_page_comment_question: 1,
      statusFollowQuestion: false,
      statusFlagClaimQuestionActive: false,
      listClaimAnswerActive: [],
      listClaimUser: [],
      listRatingUser: [],
      uuidTypeRatingStart: '',
      uuidTypeRatingLike: '',
      uuidTypeRatingThanks: '',
      uuidDefineDetailThank: '',
      uuidClaimDetail: '',
      uuidDefineDetailLike: '',
      typeOfObject: null,
      comment_question: '',
      curent_page_comment_answer: [],
      isComment: true,
      isCommentQuestion: false,
      dataRelative: [],
      showCommentBox: false
    }
  },
  computed: {
    ...mapState([
      'user',
      'authenticated'
    ]),
    addAnswer () {
      if (!this.authenticated) {
        return false
      }
      if (this.user.uuid === this.detailQuestion.created_by && !this.detailQuestion.allow_answer) {
        return true
      }
      return false
    },
    canAnswer () {
      if (!this.detailQuestion.allow_answer && this.detailQuestion.answers.length >= 3) {
        return false
      } // Đã có nhiều hơn 3 câu trả lời và chưa đồng ý thêm
      if (!this.authenticated) {
        return true
      } // Chưa đăng nhập
      if (!this.detailQuestion.answers) {
        return true
      } // Chưa có câu trả lời
      if (this.user.uuid === this.detailQuestion.created_by) {
        return false
      } // Là người đăng câu hỏi
      const check = this.detailQuestion.answers.find(item => item.created_by === this.user.uuid) // check xem trả lời chưa
      return !check
    },
    canTyping () {
      if (!this.detailQuestion.allow_answer) { // đủ câu trả lời
        return false
      }
      if (this.detailQuestion.answers) { // có câu trả lời rồi
        if (this.detailQuestion.answers.length >= 3) { // Đã yêu cầu thêm câu trả lời
          if (this.listTyping.length >= 3) {
            return false
          } else {
            return true
          }
        } else if (this.detailQuestion.answers.length + this.listTyping.length >= 3) { // chưa yêu cầu thêm câu trả lời
          return false
        } else {
          return true
        }
      } else if (this.listTyping.length >= 3) {
        return false
      } else {
        return true
      }
    }
  },
  sockets: {
    TYPING_ANSWER (data) {
      if (this.questionId === data.question_id) {
        if (this.listTyping.length < 3) {
          const check = this.listTyping.find(it => it.user_id === data.user_id)
          if (check) {
            return
          }
          this.listTyping.push(data)
        }
      }
    },
    CANCEL_TYPING_ANSWER (data) {
      if (this.questionId === data.question_id) {
        this.listTyping = this.listTyping.filter(it => it.user_id !== data.user_id)
      }
    },
    ANSWER_SUCCESS (data) {
      if (this.questionId === data.question_id) {
        this.getQuestion()
      }
    }
  },
  created () {
    this.init()
    if (this.authenticated && this.user.uuid === this.detailQuestion.owner.uuid) {
      this.loadQuestionAI()
    }
  },
  methods: {
    setFlag (data) {
      if (data.setFlagQuestion) {
        this.statusFlagClaimQuestionActive = data.setFlagQuestion
      } else {
        // check id set flag=true
        const answer = this.detailQuestion.answers.find(it => it.id === data.setFlagAnswer)
        if (answer) {
          answer.statusClaim = true
        }
      }
    },
    // hàm khởi tạo
    async init () {
      this.loading = true
      if (this.authenticated) {
        await this.checkClaim()
        await this.checkFollowQuestion()
        await this.checkObjectRatingBuyUser()
      }
      // lấy định nghĩa về kiểu rating
      await this.getRatingDefine()
      // lấy định nghĩa báo cáo vi phạm
      await this.getClaimDefine()
      await this.setLikeFollow()
      this.loading = false
    },
    async getQuestion () {
      const {data} = await qaResource.get(this.questionId)
      this.detailQuestion = data
    },
    checkClaim () {
      const listObject = [
        {
          type: this.typeRating.question,
          objectUuid: this.questionId
        }
      ]
      this.detailQuestion.answers.forEach((item) => {
        listObject.push({
          type: this.typeRating.answer,
          objectUuid: item.id
        })
      })
      this.getListClaimBuyUser(listObject)
    },
    checkFollowQuestion () {
      if (this.authenticated) {
        const dataQuery = {
          objectId: this.questionId,
          level: 1,
          userId: this.user.uuid
        }
        getFollowStatusResource.list(dataQuery)
          .then((response) => {
            if (response.data && response.status === 200) {
              this.statusFollowQuestion = response.data
            }
          })
          .catch(() => {
          })
      } else {
        this.statusFollowQuestion = false
      }
    },
    checkObjectRatingBuyUser () {
      const listObject = [
        {
          type: this.typeRating.question,
          objectUuid: this.questionId
        }
      ]
      // lấy list answer_id
      this.detailQuestion.answers.forEach((item) => {
        listObject.push({
          type: this.typeRating.answer,
          objectUuid: item.id
        })
      })
      this.getListRatingBuyUser(listObject)
    },
    async getRatingDefine () {
      const {data} = await ratingResource.list().catch(() => {
      })
      data.forEach((ratingItem) => {
        if (ratingItem.type === 1) {
          // Kiểu sao
          this.uuidTypeRatingStart = ratingItem.uuid
        }
        if (ratingItem.type === 2) {
          // Kiểu Like
          this.uuidTypeRatingLike = ratingItem.uuid
        }
        if (ratingItem.type === 3) {
          // Kiểu cảm ơn
          this.uuidTypeRatingThanks = ratingItem.uuid
        }
      })
      this.getRatingDefineDetailLike()
      this.getRatingDefineDetailThank()
    },
    // Lấy ra danh sách các định nghĩa về Claim
    async getClaimDefine () {
      const {
        data,
        status
      } = await claimDefineResource.list().catch(() => {
      })
      if (status === 200) {
        data.forEach((item) => {
          if (item.type === 1) { // Loại vi phạm khác được tùy chỉnh content
            this.uuidClaimDetail = item.uuid
          }
        })
      }
    },
    async setLikeFollow () {
      this.detailQuestion.liked = await this.checkStatus(this.questionId, 'Like')
      this.detailQuestion.answers.forEach(async (answer, ind) => {
        this.detailQuestion.answers[ind].liked = await this.checkStatus(answer.id, 'Like')
        this.detailQuestion.answers[ind].thanked = await this.checkStatus(answer.id, 'Thank')
      })
    },

    // Lấy danh sách báo cáo vi phạm của listObject
    getListClaimBuyUser (listObject) {
      const dataQuery = {
        data: listObject
      }
      claimListByUserResource.store(dataQuery)
        .then((result) => {
          if (result.data && result.status === 200) {
            this.listClaimUser = result.data
            this.listClaimUser.forEach((item) => {
              if (item.type === this.typeRating.question) {
                this.statusFlagClaimQuestionActive = true
              }
              if (item.type === this.typeRating.answer) {
                this.listClaimAnswerActive.push(item.objectUuid)
              }
            })
            this.detailQuestion.answers.forEach((value, index) => {
              this.detailQuestion.answers[index].statusClaim = this.listClaimAnswerActive.includes(value.id)
            })
          }
        })
        .catch(() => {
        })
    },
    // Lấy danh sách báo cáo vi phạm của listObject
    getListRatingBuyUser (listObject) {
      const dataQuery = {
        data: listObject
      }
      ratingListByUserResource.store(dataQuery)
        .then((response) => {
          this.listRatingUser = response.data
        })
        .catch(() => {
        })
    },
    // Lấy chi tiết định nghĩa về Rating
    async getRatingDefineDetailLike () {
      if (!this.uuidTypeRatingLike) {
        return
      }
      const {data} = await ratingDefineResource.get(this.uuidTypeRatingLike).catch(() => {
      })
      data.forEach((ratingItem) => {
        if (ratingItem.value === this.ratingValue.like) {
          this.uuidDefineDetailLike = ratingItem.uuid
        }
      })
    },
    async getRatingDefineDetailThank () {
      if (!this.uuidTypeRatingThanks) {
        return
      }
      const {data} = await ratingDefineResource.get(this.uuidTypeRatingThanks).catch(() => {
      })
      data.forEach((ratingItem) => {
        if (ratingItem.value === this.ratingValue.thank) {
          this.uuidDefineDetailThank = ratingItem.uuid
        }
      })
    },
    async typeOf (level, idQA) {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      this.typeOfObject = {
        level,
        idQA
      }
      this.$bvModal.show('modal-report')
    },
    actionReport (data) {
      this.typeOfObject = data
      this.$bvModal.show('modal-report')
    },
    // check status question
    checkQuestion () {
      return new Promise((resolve) => {
        qaResource.get(this.questionId)
          .then((res) => {
            this.tmp_detailQuestion = res.data
            resolve(false)
          })
          .catch(() => {
            this.$notify.error({
              message: 'Không thể thực hiện thao tác. Câu hỏi đã bị xóa hoặc bị báo cáo vi phạm.',
              showClose: true,
              position: 'top-right'
            })
            resolve(true)
          })
      })
    },
    // Like question
    async likeQuestionEvent () {
      if (!this.authenticated) {
        return false
      }
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const postParam = {
        type: this.typeRating.question,
        userUuid: this.user.uuid,
        objectUuid: this.questionId,
        ratingValue: this.ratingValue.like,
        defineUuid: this.uuidTypeRatingLike,
        defineDetailUuid: this.uuidDefineDetailLike
      }
      createRatingResource.store(postParam)
        .then((res) => {
          this.detailQuestion.liked = res.data
          this.detailQuestion.like++
          this.curent_page_comment_answer = 1
        })
        .catch(() => {
        })
    },
    checkStatus (objectid, type) {
      if (!this.authenticated) {
        return false
      }
      if (this.listRatingUser) {
        const checkType = this.listRatingUser.find((item) => {
          return (item.userUuid === this.user.uuid && item.objectUuid === objectid && item.ratingValue === type) || false
        })
        if (checkType) {
          return true
        }
        return false
      }
      return false
    },
    // Comment Question
    async commentQuestion () {
      this.comment_question = this.comment_question.trim()
      if (!this.comment_question || this.isCommentQuestion) {
        return false
      }
      this.isCommentQuestion = true
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      if (this.comment_question.length > 1000) {
        this.$notify.error({
          message: 'Vui lòng không nhập quá 1000 kí tự',
          showClose: true,
          position: 'top-right'
        })
        return
      }
      const dataSend = {
        question_id: this.questionId,
        content: this.comment_question.substring(0, 1000)
      }
      // Call API
      qaQuestionDetailResource.store(dataSend)
        .then((response) => {
          if (response.status === 200) {
            const data = response.data
            data.owner = this.user
            this.detailQuestion.details.unshift(data)
            if (this.detailQuestion.details.length > 5) {
              this.displayLoadmoreQ = true
            }
            this.detailQuestion.details = this.detailQuestion.details.slice(0, 5)
          }
        })
        .catch(() => {
          this.$notify.error({
            // message: error.response.data.message,
            message: 'Có lỗi xảy ra',
            showClose: true,
            position: 'top-right'
          })
        })
        .finally(() => {
          this.comment_question = ''
          this.curent_page_comment_question = 1
          this.isCommentQuestion = false
        })
    },
    answerQuestion () {
      if (!this.authenticated) {
        this.$bvModal.show('modal-login')
        return
      }
      if (!this.canTyping) {
        return
      }
      if (!this.canAnswer) {
        this.$notify.error({
          title: 'Có lỗi',
          message: 'Bạn đã trả lời câu hỏi này rồi!'
        })
      } else {
        this.$router.push('/tra-loi/' + this.questionId)
      }
    },
    async followEvent () {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      this.countTimeFollow += 1
      if (this.countTimeFollow >= 6) {
        this.$notify.error({
          title: 'Có lỗi',
          message: 'Bạn đã theo dõi và hủy theo dõi quá nhiều lần vui lòng thử lại sau'
        })
        return
      }
      const dataQuery = {
        objectType: 'CL_QUESTION',
        objectId: this.questionId,
        level: 1,
        userId: this.user.uuid
      }
      if (!this.statusFollowQuestion) {
        const result = await followResource.store(dataQuery).catch(() => {
        })
        if (result && result.status === 200) {
          this.statusFollowQuestion = true
        }
      } else {
        const result = await followResource.patch(dataQuery).catch(() => {
        })
        if (result && result.status === 200) {
          this.statusFollowQuestion = false
        }
      }
    },
    async loadMoreComment () {
      const notfound = await this.checkQuestion()
      if (notfound) {
        return
      }
      const dataQuery = {
        question_id: this.questionId,
        page: this.curent_page_comment_question + 1,
        limit: 5
      }
      getCommentQuestionResource.list(dataQuery)
        .then((response) => {
          if (response.status === 200 && response.data) {
            if (response.data.length <= 5) {
              this.displayLoadmoreQ = false
            }
            this.detailQuestion.details = this.detailQuestion.details.concat(response.data)
            if (this.detailQuestion.details.length === response.total) {
              this.displayLoadmoreQ = false
            } else if (this.detailQuestion.details.length < response.total) {
              this.displayLoadmoreQ = true
            }
            this.curent_page_comment_question++
          }
        })
        .catch(() => {
        })
    },
    async loadQuestionAI () {
      const body = {}
      if (this.detailQuestion.content) {
        body.text = this.detailQuestion.content
      }
      if (this.detailQuestion.image.length) {
        body.image_url = this.detailQuestion.image[0]
      }
      body.class_id = this.detailQuestion.class_id
      body.category_id = this.detailQuestion.category_id
      body.limit = 10
      const {data} = await searchAi(body)
      this.dataRelative = data
    },
    userVote (index, value) {
      this.dataRelative[index].rating = value
    },
    handerAddAnswer () {
      const questionId = this.detailQuestion.id
      allowAnswerResource.store({question_id: questionId})
        .then((res) => {
          if (res.status === 200) {
            this.detailQuestion.allow_answer = 1
          }
        })
    },
    handerRedirect (redirect = []) {
      let data = null
      if (redirect.length) {
        data = {}
        redirect.forEach((ele) => {
          data[ele] = this.detailQuestion[ele]
        })
      }
      if (data.category_id) {
        delete data.category_id
        this.$store.dispatch('setStoreRedirect', data)
        this.$router.push(this.detailQuestion.category.slug ? ('/' + this.detailQuestion.category.slug) : '/toan-hoc')
      } else {
        this.$store.dispatch('setStoreRedirect', data)
        this.$router.push('/')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-detail-question {
  .form-content {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding: 24px;
    @media (max-width: 1366px) {
      padding: 18px;
    }
    @media (max-width: 576px) {
      padding: 16px;
    }

    .user-info {
      .avatar {
        --avatar: 62px;
        width: var(--avatar);
        height: var(--avatar);
        margin-right: 15px;
        @media (max-width: 1366px) {
          --avatar: 52px;
        }
        @media (max-width: 576px) {
          --avatar: 48px;
        }
      }

      .user-name {
        font-weight: bold;
        font-size: 16px;
        @media (max-width: 1366px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
          font-weight: 600;
        }
      }

      .time-ago {
        font-size: 12px;
        color: #65676B;
      }

      .class-info {
        font-weight: 500;
        font-size: 14px;
        @media (max-width: 1366px) {
          font-size: 13px;
        }
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }
    }

    .question-content {
      padding-top: 15px;
      font-size: 16px;
      @media (max-width: 1366px) {
        padding-top: 13px;
        font-size: 15px;
      }
      @media (max-width: 576px) {
        padding-top: 13px;
        font-size: 14px;
      }
    }

    .question-image {
      background: #E2EBF6;
      margin-top: 15px;
      @media (max-width: 1366px) {
        margin-top: 12px;
      }
      @media (max-width: 576px) {
        margin-top: 10px;
      }

      .image-container {
        text-align: center;
        max-height: 400px;
        @media (max-width: 576px) {
          max-height: 250px;
        }
        @media (max-width: 576px) {
          max-height: 200px;
        }
      }
    }

    .question-action {
      font-size: 14px;
      margin: 20px 0 16px;
      @media (max-width: 1366px) {
        margin: 10px 0;
      }
      @media (max-width: 576px) {
        margin: 20px 0 16px;
      }

      .action {
        cursor: pointer;
        margin-right: 20px;
        @media (max-width: 576px) {
          margin-right: 25px;
        }

        &:last-child {
          margin-right: 0;
        }

        .action-number {
          vertical-align: text-top;
          margin-left: 5px;
        }
      }

      .btn-answer {
        border: 1px solid var(--primary) !important;
        border-radius: 20px;
        font-weight: 500;
        color: var(--primary);
        background: var(--white);
        margin-left: auto;
        font-size: 16px;
        padding: 5px 25px;
        @media (max-width: 1366px) {
          font-size: 14px;
          padding: 5px 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
          padding: 5px 15px;
        }

        &:disabled {
          background: #C4C4C4;
        }
      }
    }

    .form-comment {
      padding-bottom: 20px;
      @media (max-width: 1366px) {
        padding-bottom: 12px;
      }
      @media (max-width: 576px) {
        padding-bottom: 10px;
      }

      .avatar {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        @media (max-width: 576px) {
          margin-right: 7px;
        }
      }

      input {
        background: #EBEBEB;
        border-radius: 22px;
        font-size: 14px;
        color: #6B6D70;
        padding: 14px 16px 13px;
        @media (max-width: 1366px) {
          padding: 10px 13px;
        }
        @media (max-width: 576px) {
          padding: 10px 12px;
        }
      }
    }

    .list-comment {
      max-height: 500px;
      @media (max-width: 1366px) {
        max-height: 400px;
      }
      @media (max-width: 576px) {
        max-height: 400px;
      }

      .comment-item {
        padding-bottom: 15px;
        @media (max-width: 1366px) {
          padding-bottom: 10px;
        }
        @media (max-width: 576px) {
          padding-bottom: 10px;
        }
      }

      .loadmore {
        padding-bottom: 7px;
        margin-left: 46px;
        color: var(--primary);
        @media (max-width: 576px) {
        }
      }
    }

    .question-answer {
      border-top: 1px solid #E2E2E2;
      padding-top: 20px;
      @media (max-width: 1366px) {
        padding-top: 10px;
      }
      @media (max-width: 576px) {
        padding-top: 10px;
      }

      .title {
        font-weight: bold;
        font-size: 16px;
        @media (max-width: 1366px) {
          font-size: 15px;
        }
        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      .no-answer {
        padding-top: 35px;
        text-align: center;
        font-size: 14px;
        @media (max-width: 1366px) {
          padding-top: 20px;
        }
        @media (max-width: 576px) {
          padding-top: 20px;
        }

        a {
          font-weight: 500;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ml-detail-question {
  .form-content {
    .question-action {
      .active {
        color: var(--primary);
      }
    }

    .list-answer {
      .answer-item {
        &:first-child {
          margin-top: 16px;
          @media (max-width: 1366px) {
            margin-top: 10px;
          }
          @media (max-width: 576px) {
            margin-top: 0;
          }
        }

        &:last-child {
          .line {
            display: none;
          }

          .comment {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
</style>
