<template>
  <div class="container ml-lich-su-hoi-dap mb-5">
    <div class="breadcrumd-custom">
      <nuxt-link to="/">Hỏi bài</nuxt-link>
      <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon"/> <span class="active">Hỏi bài của tôi</span></div>
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <QuestionForMe/>
        <div class="page-title font-weight-bold d-flex align-items-center d-lg-block">
          Hỏi bài của tôi
        </div>
        <div class="form-data d-lg-none mb-3 mb-lg-0">
          <FormSearch placeholder="Nhập từ khóa" class="mr-3 content" @enter="searchData" :value="query.keyword"/>
          <b-dropdown v-if="tabIndex === 0" size="lg" variant="link" class="filter-mobile ml-auto" right toggle-class="shadow-none p-0 d-flex align-items-center" menu-class="pt-0" no-caret>
            <template #button-content>
              Lọc <img src="~/mlearn/icons/home/filter.svg" alt="close" class="ml-2 close pointer"/>
            </template>
            <b-dropdown-item v-for="item in list_status" :key="item.value" @click="setStatus(item.value)" :class="{'active': query.status === item.value}">{{ item.text }} ({{ item.total }})</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="tabs-custom">
          <div class="header d-flex align-items-center">
            <span class="item pointer" :class="{'active text-primary': tabIndex === 0}" @click="tabIndex = 0">Hỏi bài</span>
            <span class="item ml-3 pointer" :class="{'active text-primary': tabIndex === 1}" @click="tabIndex = 1">Tham gia trả lời</span>
            <div class="ml-auto d-none d-lg-inline-flex filter-answer-question" v-if="tabIndex === 0">
              <FormSearch placeholder="Nhập từ khóa" class="mr-3" @enter="searchData" :value="query.keyword"/>
              <el-select v-model="query.status" placeholder="Tất cả" @change="filterGetQuestionAnswer">
                <el-option v-for="item in list_status" :key="item.value" :label="item.text" :value="item.value">
                  <span>{{ item.text }} ({{ item.total }})</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="content">
            <template v-if="tabIndex === 0">
              <div v-if="listQuestion.length">
                <QuestionMe v-for="item in listQuestion" :key="item.id" :item="item"/>
              </div>
              <NoData v-if="!listQuestion.length && !loading" text="Bạn chưa có câu hỏi nào" type="question"/>
              <Pagination class="mt-3" :total="query.total" :value="query.page" :limit="query.limit" @change="getListQuestion"/>
            </template>
            <template v-else>
              <div v-if="listAnswer.length > 0">
                <AnswerMe v-for="item in listAnswer" :key="item.id" :item="item"/>
              </div>
              <NoData v-if="!listQuestion.length && !loading" text="Bạn chưa có câu trả lời nào" type="question"/>
              <Pagination class="mt-3" :total="queryAnswer.total" :value="queryAnswer.page" :limit="queryAnswer.limit" @change="getListAnswer"/>
            </template>
          </div>
          <Rank class="d-lg-none pt-3" :home="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
import QuestionForMe from '~/components/mlearn/user/question-for-me.vue'
import FormSearch from '~/components/mlearn/select/form-search.vue'
import Pagination from '~/components/mlearn/pagination/index'
import QuestionMe from '~/components/mlearn/question/me.vue'
import AnswerMe from '~/components/mlearn/answer/me.vue'
import Rank from '~/components/mlearn/rank/index.vue'

const questionResource = new Resource('qa/question-by-user')
const answerResource = new Resource('qa/answer-by-user')
const totalQuestionResource = new Resource('qa/total-question-by-user')
export default {
  components: {
    QuestionForMe,
    Pagination,
    FormSearch,
    Rank,
    QuestionMe,
    AnswerMe
  },
  layout: 'mlearn',
  middleware: 'authenticated',
  data () {
    return {
      tabIndex: 0,
      query: {
        page: 1,
        limit: 5,
        user_id: '',
        status: '',
        keyword: '',
        total: 0
      },
      queryAnswer: {
        page: 1,
        limit: 5,
        user_id: '',
        total: 0
      },
      listQuestion: [],
      listAnswer: [],
      list_status: [
        {
          value: '',
          text: 'Tất cả',
          total: 0
        },
        {
          value: 1,
          text: 'Đã trả lời',
          total: 0
        },
        {
          value: 2,
          text: 'Đợi trả lời',
          total: 0
        },
        {
          value: 3,
          text: 'Bị từ chối',
          total: 0
        }
      ],
      loading: false
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    },
    user () {
      return this.$store.state.user
    }
  },
  watch: {
    tabIndex (val) {
      this.$store.dispatch('setUserQuestionTab', val)
    }
  },
  created () {
    this.query.user_id = this.user.uuid
    this.queryAnswer.user_id = this.user.uuid
    this.tabIndex = this.$store.state.userQuestionTab || 0
    this.getTotalQuestion()
    this.getListQuestion()
    this.getListAnswer()
  },
  methods: {
    getListQuestion (page = 1) {
      this.query.page = page
      questionResource.list(this.query)
        .then((res) => {
          if (res.status === 200) {
            this.listQuestion = res.data
            this.query.total = res.total
          }
        })
        .finally(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        })
    },
    getTotalQuestion () {
      const query = {user_id: this.user.uuid}
      totalQuestionResource.list(query)
        .then((res) => {
          if (res.status === 200) {
            const data = res.data
            this.list_status[1].total = data.total_answered
            this.list_status[2].total = data.total_watting_answer
            this.list_status[3].total = data.total_reject
            this.list_status[0].total = data.total_answered + data.total_watting_answer + data.total_reject
          }
        })
    },
    getListAnswer (page = 1) {
      this.queryAnswer.page = page
      answerResource.list(this.queryAnswer)
        .then((result) => {
          if (result.status === 200) {
            this.listAnswer = result.data
            this.queryAnswer.total = result.total
          }
        })
        .finally(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          })
        })
    },
    redirectDetailQuestion (questionId) {
      this.$router.push({
        path: '/cau-hoi-cong-dong/' + questionId
      })
    },
    filterGetQuestionAnswer () {
      // reset page to 1
      this.query.page = 1
      this.getListQuestion()
    },
    setStatus (value) {
      this.query.status = value
      this.filterGetQuestionAnswer()
    },
    searchData (keyword) {
      this.query.keyword = keyword
      this.getListQuestion()
    }
  }
}
</script>

<style lang="scss" scoped>
.ml-lich-su-hoi-dap {
  .el-popper {
    top: 300px;
  }

  .form-data > * {
    overflow: inherit;
  }

  .page-title {
    font-size: 24px;
    margin: 24px 0 10px 0;
    @media (max-width: 576px) {
      font-size: 16px;
      margin: 16px 0 5px 0;
    }
  }

  .tabs-custom {
    .header {
      border-bottom: 1px solid #EBEBEB;
      margin-bottom: 10px;
      @media (max-width: 576px) {
        justify-content: space-around;
      }

      .item {
        font-weight: 500;
        color: #65676B;
        padding: 5px 36px 15px 0;
        font-size: 16px;
        @media (max-width: 576px) {
          padding: 0 0 8px;
          font-size: 14px;
        }

        &.active {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            background: var(--primary);
            border-radius: 14px;
            height: 2px;
            width: 90%;
            @media (max-width: 576px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ml-lich-su-hoi-dap {
  .filter-mobile {
    .dropdown-toggle {
      font-size: 14px;
      color: initial;
    }

    .dropdown-menu {
      li {
        &.active {
          .dropdown-item {
            color: var(--primary);
          }
        }
      }
    }
  }

  .filter-answer-question {
    .form-search-data {
      max-width: 200px;
    }

    .el-select {
      max-width: 150px;
    }
  }
}
</style>
