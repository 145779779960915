<template>
  <div class="ml-lich-su-thanh-toan mb-0 mb-lg-5">
    <div class="container">
      <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Lịch sử  thanh toán</span></div>
    </div>
    <div class="container container-mobile">
      <div class="row">
        <div class="col-lg-10 offset-lg-1">
          <div class="content">
            <div class="title font-weight-bold">Lịch sử thanh toán</div>
            <div class="group-table">
              <table class="table table-bordered mt-2">
                <thead class="text-center">
                  <tr>
                    <th width="100">STT</th>
                    <th>Sản phẩm</th>
                    <th width="150">Đơn giá</th>
                    <th width="170">Ngày mua</th>
                    <th width="200">Trạng thái</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(history, ind) in histories" :key="ind" class="text-center">
                    <td>{{ ind + 1 }}</td>
                    <td align="left"><span class="d-lg-none">{{ ind + 1 }}. </span>{{history.name}}</td>
                    <td align="left">{{ $helper.formatMoney(history.amount,'.') }} đ</td>
                    <td>{{ history.created_at | parseTime('{d}/{m}/{y}') }}</td>
                    <td :class="{'activing': !history.is_expired, 'end': history.is_expired}">{{history.is_expired ? 'Đã kết thúc' : 'Đang hoạt động'}}</td>
                  </tr>
                  <tr v-if="!histories.length">
                    <td class="d-lg-none"></td>
                    <td colspan="10" align="center">Chưa có lịch sử thanh toán</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cookieParser from 'cookieparser'
import Resource from '~/common/api/resource'
const paymentHistoryResource = new Resource('order/history')
export default {
  layout: 'mlearn',
  middleware: 'authenticated',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    let token = ''
    if (req && req.headers && req.headers.cookie) {
      const requestCookies = cookieParser.parse(req.headers.cookie)
      token = requestCookies.access_token || ''
    }
    const paymentHistory = await paymentHistoryResource.list({}, token)
    const histories = paymentHistory.data
    return {
      histories
    }
  }
}
</script>

<style lang="scss" scoped>
  .ml-lich-su-thanh-toan{
    .content{
      background: var(--white);
      border-radius: 8px;
      padding:24px;
      border: 1px solid #CED4DA;
      @media (max-width:576px) {
        border-radius: 0;
        padding:16px 16px 30px;
        border: 0;
      }
      .title{
        font-size: 24px;
        @media (max-width:576px) {
          font-size: 16px;
        }
      }
      .group-table{
        @media (max-width:576px) {
          margin:0 -16px;
        }
      }
      table{
        background: var(--white);
        border: 1px solid #EBEBEB;
        border-radius: 8px;
        @media (max-width:576px) {
          border:0;
        }
        thead{
          background: #F0F0F0;
          border: 1px solid #EBEBEB;
          border-radius: 8px 8px 0px 0px;
          @media (max-width:576px) {
            background: #F0F0F0;
            border:0;
          }
          tr{
            th{
              border:0;
              font-size: 16px;
              @media (max-width:576px) {
                font-size: 14px;
              }
              &:nth-child(1){
                @media (max-width:576px) {
                  display: none;
                }
              }
              &:nth-child(2){
                @media (max-width:576px) {
                  text-align: left;
                }
              }
              &:nth-child(3){
                @media (max-width:576px) {
                  width: 75px;
                }
              }
              &:nth-child(4){
                @media (max-width:576px) {
                  width: 85px;
                }
              }
              &:nth-child(5){
                @media (max-width:576px) {
                  width: 85px;
                  text-align: left;
                }
              }
            }
          }
        }
        tbody{
          tr{
            td{
              border: 1px solid #CED4DA;
              @media (max-width:576px) {
                border:0;
              }
              &:first-child{
                @media (max-width:576px) {
                  display: none;
                }
              }
              &.activing{
                color: #2DC514;
                font-weight: 500;
              }
              &.end{
                color: var(--danger);
                font-weight: 500;
              }
              &:nth-child(5){
                @media (max-width:576px) {
                  text-align: left;
                }
              }
            }
          }
        }
        th, td{
          padding: .5rem;
        }
      }
    }
  }
</style>
