<template>
  <div class="text-center">
    <img src="~/mlearn/images/event/invite.png" />
  </div>
</template>

<script>
export default {
  layout: 'event'
}
</script>
