<template>
  <div></div>
</template>

<script>
export default {
  name: 'DeepLink',
  layout: 'empty',
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  mounted () {
    this.redirect()
  },
  methods: {
    redirect () {
      const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
        .includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
      if (this.isMobile) {
        if (isIOS) {
          window.location.href = 'mLearn://'
          setTimeout(function () {
            window.location.href = 'https://apps.apple.com/app/id1609536677' // Fallback to App Store
          }, 1000)
        } else {
          window.location.href = 'intent://#Intent;scheme=mlearn;package=com.elcom.mlearn;end'
        }
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped>

</style>
