<template>
  <div class="container container-mobile mb-5 ml-huong-dan-su-dung">
    <div class="content">
      <div class="support-title">Hướng dẫn sử dụng</div>
      <b-tabs content-class="mt-3 min-height" v-model="activeIndex">
        <b-tab>
          <template #title>
            <span class="d-none d-lg-block">Hướng dẫn thanh toán</span>
            <span class="d-lg-none">Thanh toán</span>
          </template>
          <div v-html="contentTab1"></div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-none d-lg-block">Hướng dẫn sử dụng gói sub</span>
            <span class="d-lg-none">Sử dụng gói sub</span>
          </template>
          <div v-html="contentTab2"></div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="d-none d-lg-block">Hướng dẫn học Gia sư</span>
            <span class="d-lg-none">Học Gia sư</span>
          </template>
          <div v-html="contentTab3"></div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
const supportPaymentResource = new Resource('price/support-payment')

export default {
  layout: 'mlearn',
  async asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const activeIndex = parseInt(query.i) || 0
    const { data } = await supportPaymentResource.list()
    return {
      support: data,
      activeIndex
    }
  },
  computed: {
    contentTab1 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANTHANHTOAN')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab2 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANSUDUNGGOISUB')
      return data?.content || 'Chưa có nội dung'
    },
    contentTab3 () {
      if (!this.support.length) {
        return 'Chưa có nội dung'
      }
      const data = this.support.find(item => item.type === 'HUONGDANHOCGIASU')
      return data?.content || 'Chưa có nội dung'
    }
  }
}
</script>
<style lang="scss">
.ml-huong-dan-su-dung{
  .content{
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding:24px;
    margin-top:20px;
    @media (max-width:576px) {
      box-shadow: none;
      border-radius: 0;
      padding:16px;
      margin-top:10px;
    }
    @media (max-width:1366px) {
      padding:18px;
      margin-top:12px;
    }
    .support-title{
      font-weight: bold;
      text-transform: uppercase;
      font-size: 24px;
      @media (max-width:576px) {
        font-size: 16px;
      }
      @media (max-width:1366px) {
        font-size: 18px;
      }
    }
    .tabs{
      .nav-tabs{
        border-bottom: 1px solid #EBEBEB;
        @media (max-width:576px) {
          display: flex;
          white-space: nowrap;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;
        }
        .nav-item{
          margin-right: 30px;
          @media (max-width:576px) {
            margin-right: 16px;
          }
          @media (max-width:1366px) {
            margin-right: 18px;
          }
          &:last-child{
            margin-right: 0;
          }
          .nav-link{
            font-weight: 500;
            border:none;
            color: #65676B;
            background: transparent;
            font-size: 16px;
            padding: 0.5rem 0;
            @media (max-width:576px) {
              font-size: 14px;
              padding: 0.35rem 0;
            }
            @media (max-width:1366px) {
              font-size: 15px;
            }
            &.active{
              color: var(--primary);
              font-weight: bold;
              position: relative;
              &::before{
                content: '';
                background: var(--primary);
                position: absolute;
                bottom:0;
                left:50%;
                transform: translateX(-50%);
                height: 4px;
                width:70px;
                @media (max-width:576px) {
                  height: 3px;
                  width:50px;
                }
                @media (max-width:1366px) {
                  height: 3px;
                  width: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
  .min-height{
    min-height: 60vh;
    @media (max-width:576px) {
      min-height: 50vh;
    }
  }
}
</style>
